// src/globalState/initialState.js

export const initialState = {
    user: {
        firstName: 'Mitch',
        lastName: '',
        email: '',
        phone: '',
        birthdate: ''
    },
    session: null,
    userIsknown: true,
    userIsAuthenticated: true,  
    userForgotPassword: false,
    userChangeEmail: false,
    userUnreadComms: 0,
    userDaysToExpiry: 0,

    drawerIsOpen: false,
    drawerWidthIs: 250,

    webMenuIsOpen: false,
    webMenuTarget: null,
    mobileMenuIsOpen: false,
    mobileMenuTarget: null,
    profileIsOpen: false,
    profileMenuTarget: null,

    textMessages: [
        {id: 1, message: 'mess 1'},
        {id: 2, message: 'mess 2'},
        {id: 3, message: 'mess 3'}
    ],
    notes: [
        {id: 1, note: 'note 1'},
        {id: 2, note: 'note 2'},
        {id: 3, note: 'note 3'},
        {id: 4, note: 'note 4'}
    ],
    cartItems: [
        {id: 1, item: 'this'},
        {id: 2, item: 'that'} 
    ],
    products: [
        { id: '1', title: 'AITO Hats', discription: '', price: 15.99, inStock: 10 },
        { id: '2', title: 'AITO Mouse Pads', discription: '', price: 5.99, inStock: 10 },
        { id: '3', title: 'AITO Coffee Cups', discription: '', price: 6.99, inStock: 10 },
        { id: '4', title: 'AITO Pens', discription: '', price: 4.99, inStock: 10 }
    ],
    services: [
        { id: 's1-1', category: 'Cloud', title: 'Service 1: month to month', discription: '', price: 29.99 },
        { id: 's1-2', category: 'Cloud', title: 'Service 1: 1 year, monthly payment', discription: '', price: 19.99 },
        { id: 's1-3', category: 'Cloud', title: 'Service 1: 1 year, annual payment', discription: '', price: 9.99 },
        { id: 's2-1', category: 'Cloud', title: 'Service 2: month to month', discription: '', price: 59.99 },
        { id: 's2-2', category: 'Cloud', title: 'Service 2: 1 year, monthly payment', discription: '', price: 49.99 },
        { id: 's2-3', category: 'Cloud', title: 'Service 2: 1 year, annual payment', discription: '', price: 39.99 },
        { id: 's2-3', category: 'Walk-through', title: 'View and share screen: hourly', discription: '', price: 50.00 },
    ],
    courses: [
        { id: 'course-1', category: 'React', title: 'bla bla', discription: '', price: 9.99 },
        { id: 'course-2', category: 'Node', title: 'bla bla', discription: '', price: 9.99 },
        { id: 'course-3', category: 'Wolfram', title: 'bla bla', discription: '', price: 9.99 }
    ],
    walkThroughs: [
        { id: 'wt1-1', category: 'React', title: 'bla bla', discription: '', price: 50.00 },
        { id: 'wt1-2', category: 'Node', title: 'bla bla', discription: '', price: 75.00 },
        { id: 'wt1-3',  category: 'Wolfram', title: 'bla bla', discription: '', price: 100.00 }
    ],
    blogs: [
        { id: 'blog1-1', category: 'React',  title: 'bla bla',  discription: ''},
        { id: 'blog1-2', category: 'Node',  title: 'bla bla',  discription: '' },
        { id: 'blog1-3', category: 'Wolfram',  title: 'bla bla',  discription: '' },
        { id: 'blog2-1', category: 'Processes',  title: 'bla bla',  discription: '' },
        { id: 'blog2-2', category: 'Analysis',  title: 'bla bla',  discription: '' },
        { id: 'blog2-3', category: 'Data Science',  title: 'bla bla', discription: '' }
    ],
  }
  
  