// src/auth/SignUp.js
import React, { useContext } from 'react'
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  CardActions,
  Button,
  Typography,
  TextField
} from "@material-ui/core";

import Context from '../state/Context'

import { useInput } from "../hooks/useInput";
import { useSubmit } from "../hooks/useSubmit";
import { validations } from "../utilities/validations.js";

import { useSignInStyles } from '../hooks/useStyles'


const SignUp = (props) => {
  const classes = useSignInStyles()
  const { state, dispatch } = useContext( Context )
  // console.log('>>>-SignUp-props->', props)
  // console.log('>>>-SignUp-state->', state)
  // console.log('>>>-SignUp-state.userIsknown->', state.userIsknown)
  // console.log('>>>-SignUp-state.userIsAuthenticated->', state.userIsAuthenticated)

  // (name, type, defaultValue, regex)
  const firstName = useInput("firstName", 'text', "", validations.ANYTHING);
  const lastName = useInput("lastName", 'text',"", validations.ANYTHING);
  const email = useInput("email", 'email',"", validations.EMAIL);
  const confirmEmail = useInput("confirmEmail", 'email',"", validations.EMAIL);
  const password = useInput("password", 'password', "", validations.PASSWORD);
  const confirmPassword = useInput("confirmPassword", 'password',"", validations.PASSWORD);
  const code = useInput("code", 'text', "", validations.ANYTHING);

  const { 
    // values, 
    // errors, 
    isSubmitting, 
    handleSignUp,
    handleSignUpConfirm,
    handleResesndSignUpConfirm
  } = useSubmit(
    signup,// callback
    props,
    {firstName, lastName, email, confirmEmail, password, confirmPassword, code},
    dispatch
)

function signup () {
  // console.log('>>>-SignUp-signup-values->',values)
  // console.log('>>>-SignUp-signup-errors->',errors)
}

  const SignUpForm = () => {
    return (
      <Grid container className={classes.grid} spacing={16}>
        <Grid item xs={12}>
        <Grid container className={classes.demo} justify="center" spacing={Number(16)}>
        <Card className={classes.card}>
        <CardHeader className={classes.cardHeader}
        avatar={
          <Avatar aria-label="Recipe" className={classes.avatar}>
           
          </Avatar>
        }
        title={
          <div style={{color: 'white'}}>
              AITO Consulting
          </div>
        }
      >
      </CardHeader>
      <CardContent>
      <Typography 
        variant="h5" 
        style={{background: '#304ffe', color: 'white', padding: 5}}
        >
        Sign Up Form
      </Typography>
      <form 
        onSubmit={handleSignUp}
        className={classes.form} 
      >
        <div className={classes.input} style={{padding: 5}}>
          <TextField 
            label="First Name" 
            name='firstName' 
            type='text' 
            {...firstName.props}
            required
            variant="outlined" 
            fullWidth={true}
          />
          {firstName.props.error && (
            <Typography variant="body1" color="error">
              Invalid first name
            </Typography>
          )}
        </div>
        <div className={classes.input} style={{padding: 5}}>
          <TextField 
            label="Last Name" 
            name='lastName' 
            type='text' 
            {...lastName.props}
            required
            variant="outlined" 
            fullWidth={true}
          />
          {lastName.props.error && (
            <Typography variant="body1" color="error">
              Invalid last name
            </Typography>
          )}
        </div>
        <div className={classes.input} style={{padding: 5}}>
          <TextField 
            label="Email" 
            name='email' 
            type='email' 
            {...email.props}
            required
            variant="outlined" 
            fullWidth={true}
          />
          {email.props.error && (
            <Typography variant="body1" color="error">
              Invalid email
            </Typography>
          )}
          {
            email.props.value === confirmEmail.props.value ? null :
            <Typography variant="body1" color="error">
            Emails don't match.
            </Typography>
          }
        </div>
        <div className={classes.input} style={{padding: 5}}>
          <TextField 
            label="Confirm Email" 
            name='confirmEmail' 
            type='email' 
            {...confirmEmail.props}
            required
            variant="outlined" 
            fullWidth={true}
          />
          {confirmEmail.props.error && (
            <Typography variant="body1" color="error">
              Invalid email
            </Typography>
          )}
        </div>
        <div className={classes.input} style={{padding: 5}}>
          <TextField 
          label="Password" 
          name='password' 
          type='password' 
          {...password.props}
          required
          variant="outlined" 
          fullWidth={true}
          />
          {password.props.error && (
            <Typography variant="body1" color="error">
              Minimum 8 charaters.
            </Typography>
          )}
          {
            password.props.value === confirmPassword.props.value ? null :
            <Typography variant="body1" color="error">
            Passwords don't match.
            </Typography>
          }
        </div>
        <div className={classes.input} style={{padding: 5}}>
          <TextField 
          label="Confirm Password" 
          name='confirmPassword' 
          type='password' 
          {...confirmPassword.props}
          required
          variant="outlined" 
          fullWidth={true}
          />
          {confirmPassword.props.error && (
            <Typography variant="body1" color="error">
              Password problems.
            </Typography>
          )}
        </div>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={ 
            (!email.props.value || 
            !confirmEmail.props.value || 
            !password.props.value || 
            !confirmPassword.props.value) ||
            (email.props.value !== confirmEmail.props.value ||
            password.props.value !== confirmPassword.props.value)
            }
        >
          {isSubmitting ? 'Signing Up...' : 'Sign Up'}
        </Button>
        <br/>
        <p style={{color: '#0026ca'}}>Check your email for a sign up confirmation code.</p>
      </form>
      <CardActions className={classes.actions} disableActionSpacing>

      </CardActions>
      </CardContent>
  
        </Card>
        </Grid>
        </Grid>
      </Grid>
    )
  }

  const SignUpConfirmForm = () => {
    return (
      <Grid container className={classes.grid} spacing={16}>
        <Grid item xs={12}>
        <Grid container className={classes.demo} justify="center" spacing={Number(16)}>
        <Card className={classes.card}>
        <CardHeader className={classes.cardHeader}
        avatar={
          <Avatar aria-label="Recipe" className={classes.avatar}>
           
          </Avatar>
        }
        title={
          <div style={{color: 'white'}}>
              AITO Consulting
          </div>
        }
      >
      </CardHeader>
      <CardContent>
      <Typography 
        variant="h5" 
        style={{background: '#304ffe', color: 'white', padding: 5}}
        >
        Sign Up Confirmation
      </Typography>
      <form 
        onSubmit={handleSignUpConfirm}
        className={classes.form} 
      >
        <div className={classes.input} style={{padding: 5}}>
          <TextField 
            label="Sign Up Confirmation Code" 
            name='code' 
            type='text' 
            {...code.props}
            required
            variant="outlined" 
            fullWidth={true}
          />
          {code.props.error && (
            <Typography variant="body1" color="error">
              Invalid code
            </Typography>
          )}
        </div>
        <br/><br/>
        <Grid container className={classes.demo} justify="center" spacing={Number(16)}>
  
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={{padding: 5, margin:10, width:150}}
          disabled={ !code.props.value }
        >
          {isSubmitting ? 'Comfirming...' : 'Confirm'}
        </Button>
        
        <Button
          onClick={handleResesndSignUpConfirm}
          color="primary"
          variant="contained"
          style={{padding: 5, margin:10, width:150}}
          disabled={ true }
        >
          {false ? 'Resending...' : 'Resend'}
        </Button>
        
        </Grid>
        <br/>
      <p></p>
      </form>
      
      <CardActions className={classes.actions} disableActionSpacing>

      </CardActions>
      </CardContent>
  
        </Card>
        </Grid>
        </Grid>
      </Grid>
    )
  }



  return (
    <div className='ForgotPassword'>
      {
        !state.userIsknown ? SignUpForm() : SignUpConfirmForm()
      }
    </div>
  )
}

export default SignUp
