// src/hooks/useInput.js

import { useState } from 'react'

export function useInput (name, type, draftValue, regex) {
    const [value, setValue] = useState(draftValue)
    const [error, setError] = useState(null)
    // console.log('>>>-useInput-name->', name)
    // console.log('>>>-useInput-type->', type)
    // console.log('>>>-useInput-apiSymbol->', apiSymbol)
    // console.log('>>>-useInput-draftValue->', draftValue)
    // console.log('>>>-useInput-regex->', regex)
    // console.log('>>>-useInput-value->', value)
    // console.log('>>>-useInput-error->', error)

    function handleChange (event) {
        setValue(event.target.value)
        setError(null)
    }

    function handleValidation(value, regex) {
        if (value && regex && value.match(regex)) {
          return true
        } else {
          return false
        }
    }
    
    function handleValidate () {
        const valid = handleValidation(value, regex)
        setError(!valid)
        return valid
    }

    function handleBlur () {
        handleValidate()
    }

    return {
        props: {
            name,
            type,
            value,
            error,
            onChange: handleChange,
            onBlur: handleBlur
        },
        validate: handleValidate
    }
}
