// src/pages/channel/Channel.js

import React from 'react'

const Channel = () => {


    return (
        <div>
            <h1>Channel page...</h1>
            <h3>YouTube</h3>
            <p>none for now...</p>
            <h3>Twitch</h3>
            <p>https://www.twitch.tv/videos/Video%7D%7C%7B457922436</p>
        </div>

    )
}

export default Channel