// src/pages/HomeInfoCard.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Collapse,
    Avatar,
    IconButton,
    Typography
} from '@material-ui/core';

import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';

const styles = theme => ({
  card: {
    width: '100%',
    height: 'auto',
    background: '#EEEEEE',
  },
  cardHeader: {
    background: '#0026ca',
    color: 'white',
  },
  actions: {
    display: 'flex',
    background: '#9effff',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    background: '#0026ca',
    color: 'white',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#9effff',
    color: '#0026ca'
  },
});


const HomeInfoCard = (styles) => {
    const [ isExpanded, setIsExpanded ] = useState(false)

    const handleExpandClick = () => {
        setIsExpanded( !isExpanded );
    };

    const { classes } = styles;

    return (
      <Card className={classes.card}>
        <CardHeader className={classes.cardHeader}
          title={
                <div style={{color: 'white'}}>
                    Process Options
                </div>
            }
        />
        <CardContent>
          <Typography paragraph >
          {/* <Typography paragraph justify="Left" justifyContent="Left" align="Left"> */}
          Every process is set of one or more choices - options.  Options can be economically valued.  Processes can be valued!  What does it mean to value a process?  It means exactly the same as valuing an option. 
          </Typography>
          <Typography variant="h6">
          Why value processes?  
          </Typography>
          <Typography paragraph >
          Increasing net value is a fundamental human and natural endeavor with its own reward - the ability to continue processing!
          </Typography>
          <Typography variant="h6">
          How can processes be valued?
          </Typography>
          <Typography paragraph >
          Fortunately there are methods, at a minimum frameworks, for evaluating how options (processes) can be valued. 
          </Typography>

        </CardContent>
        <CardActions className={classes.actions} disableActionSpacing>
          <IconButton aria-label="Add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="Share" >
            <ShareIcon />
          </IconButton>
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: isExpanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={isExpanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph styles={{align: "left"}}>Continuing...</Typography>
            <Typography paragraph justify="Left" justifyContent="Left" align="Left">
            All processes of an organization impact the financial resources available to the organization for the purpose of continuing, speeding-up, slowing-down, stopping or fundamentally changing, their processes.  At AITO Consulting our focus is the connection between processes and their economics.
            </Typography>
            <Typography paragraph justify="Left" justifyContent="Left" align="Left">
            This makes the operational performance history of processes an important asset for organizations.  This is where technology, data science, process engineering, accounting, law and regulation, and economics join to specify the target area and harvest value.  Now, for the questions...
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    );
}

HomeInfoCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeInfoCard);