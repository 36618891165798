// src/hooks/useSubmit.js

import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'


export function useSubmit( callback, props, draftValues, dispatch) {
    // console.log('>>>-useSubmit-props->', props)
    const [values, setValues] = useState(draftValues)
    const [results, setResults] = useState(null)
    const [errors, setErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)

    // console.log('>>>-useSubmit-callback->', callback)
    // console.log('>>>-useSubmit-props->', props)
    // console.log('>>>-useSubmit-draftValues->', draftValues)
    // console.log('>>>-useSubmit-dispatch->', dispatch)
    // console.log('>>>-useSubmit-values->', values)
    // console.log('>>>-useSubmit-errors->', errors)
    // console.log('>>>-useSubmit-isSubmitting->', isSubmitting)

    useEffect(
        () => {
          if (Object.keys(errors).length === 0 && isSubmitting) {
            callback()
          }
        },
        [errors]
      )

// handleTESTSubmit for DEV & testing    
    // function handleTESTSubmit (event) {
    //     event.preventDefault() // prevent page refresh
    
    //     // blur everything to validate again
    //     const errorItems = inputs.filter(input => !input.validate())
    //     setErrorItems(errorItems)
    //     if (errorItems && errorItems.length === 0) {
    //       success &&
    //         success(
    //           inputs.map(({ props: { name, value } }) => ({
    //             name,
    //             value
    //           }))
    //         )
    //     }

// ============================================
// handleSignIn
const handleSignIn = async (event) => {
    event.preventDefault()
    setIsSubmitting( true )
    setValues({
        email: draftValues.email.props.value,
        password: draftValues.password.props.value
    })
    console.log('>>>-useSubmit-handleSignIn-draftValues->', draftValues)
    try {
        const user = await Auth.signIn(
            draftValues.email.props.value, 
            draftValues.password.props.value
            )
        dispatch({ type: 'USER_SIGN_IN', payload: user })
        // setToken(user.jwt)
        setErrors({})
        setIsSubmitting( false )
        return props.history.push('/home')
    } 
    catch (err) {
      alert(err.message)
      setIsSubmitting( false )
    } 
  }
// ============================================
// handleSignUp
const handleSignUp = async (event) => {
    event.preventDefault()
    setIsSubmitting( true )
    setValues({
        firstName: draftValues.firstName.props.value,
        lastName: draftValues.lastName.props.value,
        email: draftValues.email.props.value,
        password: draftValues.password.props.value
    })
    // console.log('>>>-useSubmit-handleSignUp-draftValues->', draftValues)
    try {
      const user = await Auth.signUp( 
        {
          username: draftValues.email.props.value,
          password: draftValues.password.props.value,
          attributes: {
            email: draftValues.email.props.value
          }
        }
       )
      dispatch({ type: 'USER_SIGN_UP', payload: user })
      setErrors({})
      setIsSubmitting( false )
    } 
    catch(err) {
      console.log('errors signing in! :', err)
      alert(err.message)
      setErrors(err.message)
      setIsSubmitting( false )
    }
  }
// ============================================
// handleSignUpConfirm
const handleSignUpConfirm = async (event) => {
    event.preventDefault()
    // console.log('>>>-useSubmit-handleSignUpConfirm-draftValues->', draftValues)
    // let the auth provider check errors
    // setErrors(validate(values))
    setIsSubmitting(true)
    try {
      await Auth.confirmSignUp(
        draftValues.email.props.value, 
        draftValues.code.props.value
        )
      const user = await Auth.signIn(
        draftValues.email.props.value, 
        draftValues.password.props.value
        )
      dispatch({ type: 'USER_SIGN_UP_CONFIRM', payload: user })
      setErrors({})
      setIsSubmitting( false )
      props.history.push('/home')
    }
    catch (err) {
      console.log('errors signing in! :', err)
      alert(err.message)
      setErrors(err.message)
      setIsSubmitting( false )
    } 
   }
// ============================================
// handleResesndSignUpConfirm
const handleResesndSignUpConfirm = async (event) => {
  event.preventDefault()
  console.log('>>>-useSubmit-handleResesndSignUpConfirm->')
  // Auth.resendSignUp()
}


// ============================================
// handleWCGetOptionVals
const handleCalcOptionVals = async (event) => {
  event.preventDefault()
  setValues([])
  setIsSubmitting(true)
  // console.log('>>>-useSubmit-handleCalcOptionVals-draftValues->',draftValues)
  setValues([
    {symbol: 'flavor', input: draftValues.optionFlavor.props.value},
    {symbol: 'type', input: draftValues.optionType.props.value},
    {symbol: 'u', input: draftValues.underlying.props.value},
    {symbol: 'k', input: draftValues.strike.props.value},
    {symbol: 'v', input: draftValues.volatility.props.value},
    {symbol: 'x', input: draftValues.expiry.props.value},
    {symbol: 'd', input: draftValues.dividendRate.props.value},
    {symbol: 'r', input: draftValues.riskFreeRate.props.value}
  ])
  // console.log('>>>-useSubmit-handleCalcOptionVals-values->',values)
  const prepURL = (
  'flavor=' + draftValues.optionFlavor.props.value + '&' +
  'type=' + draftValues.optionType.props.value + '&' +
  'u=' + draftValues.underlying.props.value + '&' +
  'k=' + draftValues.strike.props.value + '&' +
  'v=' + draftValues.volatility.props.value + '&' +
  'x=' + draftValues.expiry.props.value + '&' +
  'd=' + draftValues.dividendRate.props.value + '&' +
  'r=' + draftValues.riskFreeRate.props.value + '&'
  )
  // const prepURL = values.map(item => (
  //   item.symbol + '=' + item.input + '&'
  // ) )
  // console.log('>>>-useSubmit-handleCalcOptionVals-prepURL->',prepURL)
  const varsURL = Object.values(prepURL)
    .join('')
    .slice(0, -1)
  const baseURL = 'https://www.wolframcloud.com/objects/mitch/'
  const endPoint = 'options/?'
  const apiString = baseURL + endPoint + varsURL
  // console.log(
  //   '>>>-VariableInputForm-handleFormSuccess-apiString->',
  //   apiString
  // )
  axios.get(apiString).then(res => {
    // console.log(
    //   '>>>-VariableInputForm-handleFormSuccess-res.data->',
    //   res.data
    // )
    const optionTVs = res.data
    // console.log(
    //   '>>>-VariableInputForm-handleFormSuccess-optionTVs->',
    //   optionTVs
    // )
    setResults(optionTVs)
    // console.log(
    //   '>>>-VariableInputForm-handleFormSuccess-results->',
    //   results
    // )
    // const callOpt = optionTVs.call
    // const putOpt = optionTVs.put
    // console.log('>>>-VariableInputForm-handleFormSuccess-callOpt->', callOpt)
    // console.log('>>>-VariableInputForm-handleFormSuccess-putOpt->', putOpt)
  })
  setErrors({})
  setIsSubmitting( false )

}


// ============================================
// ============================================
// all objects & functions return here...
  return {
    values,
    results,
    errors,
    isSubmitting,

    handleSignUp,
    handleSignUpConfirm,
    handleResesndSignUpConfirm,
    handleSignIn,
    handleCalcOptionVals
    }
}

