// src/pages/Projects.js
import React from 'react'

const Projects = () => {

    return (
        <h3>Projects</h3>
    )
}

export default Projects