// src/auth/NotFound.js
import React from 'react'

const NotFound = () => {

    return (
        <h3>NotFound</h3>
    )
}

export default NotFound