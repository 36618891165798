// src/index.js
import React, { useContext, useReducer } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom';

import Amplify from 'aws-amplify';
import auth100 from './aws-exports';

import Context from './state/Context'
import Reducer from './state/Reducer'
import App from './App';

Amplify.configure(auth100);

const Root = () => {
    const initialState = useContext( Context )
    // console.log('>>>-index-Root-initialState->', initialState)
    const [ state, dispatch ] = useReducer( Reducer, initialState )
    // console.log('>>>-index-Root-state->', state)
    // console.log('>>>-index-Root-dispatch->', dispatch)
    let imgUrl = 'abstract-process-bg-4.jpg'; 

    return (
        <BrowserRouter>
            <Context.Provider value={{ state, dispatch }}>
            <div 
                className = 'Component-Bg'
                style = {{ backgroundImage: 'url(' + imgUrl + ')', 
                            backgroundSize: 'cover', 
                            backgroundPosition: 'center center',
                            backgroundRepeat: 'no-repeat repeat',
                            backgroundColor: 'black',
                        }}
              >
                <App />
              </div>
            </Context.Provider>
        </BrowserRouter>
    )
}

ReactDOM.render( <Root />, document.getElementById('root') )
