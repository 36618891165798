// src/state/Context.js

import React from 'react'
import { initialState } from './initialState'

export default React.createContext({
    user: initialState.user,
    session: initialState.session,
    userIsknown: initialState.userIsknown,
    userIsAuthenticated: initialState.userIsAuthenticated,
    userForgotPassword: initialState.userForgotPassword,
    userChangeEmail: initialState.userChangeEmail,
    userUnreadComms: initialState.userUnreadComms,
    userDaysToExpiry: initialState.userDaysToExpiry,

    drawerIsOpen: initialState.drawerIsOpen,
    drawerWidthIs: initialState.drawerWidthIs,

    webMenuIsOpen: initialState.webMenuIsOpen,
    webMenuTarget: initialState.webMenuTarget,
    mobileMenuIsOpen: initialState.mobileMenuIsOpen,
    mobileMenuTarget: initialState.mobileMenuTarget,
    
    profileIsOpen: initialState.profileIsOpen,
    profileMenuTarget: initialState.profileMenuTarget,

    products: initialState.products,
    services: initialState.services,
    cartItems: initialState.cartItems,
    blogs: initialState.blogs,
    courses: initialState.courses,
    walkThroughs: initialState.walkThroughs,
    textMessages: initialState.textMessages,
    notes: initialState.notes, 

    addToCart: item => {},
    removeFromCart: itemId => {}
})