// src/utilities/ChoiceLists.js

import React from 'react'
// get our fontawesome imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faWalking as WalkThroughIcon,
  faGlasses as GlassesIcon,
  faChartLine as ReportingIcon,
  faCalculator as ComputationIcon,
//   faDatabase as DataIcon,
//   faWindowMaximize as WebIcon,
  faBlog as BlogIcon,
  faFilm as MovieIcon,
  faCogs as ProcessIcon,
  // faSchool as CoursesIcon,
//   faAngleDoubleRight as RightDoubleIcon,
  faCodeBranch as OptionIcon
//   faLockOpen as LockOpenIcon,
//   faLock as LockClosedIcon
} from "@fortawesome/free-solid-svg-icons";

export const drawerDataChoices = [
    // {
    //     "index": 1,
    //     "name": 'Source Data', 
    //     "icon": '',
    //     "path": "",
    // },
    {
        "index": 2,
        "name": 'Wrangling', 
        "icon": '',
        "path": "",
    },
    {
        "index": 3,
        "name": 'Validation', 
        "icon": '',
        "path": "",
    },
    {
        "index": 4,
        "name": 'Analysis', 
        "icon": '',
        "path": "",
    },
    // {
    //     "index": 5,
    //     "name": 'Modeling', 
    //     "icon": '',
    //     "path": "",
    // },
    // {
    //     "index": 6,
    //     "name": 'Pipelines', 
    //     "icon": '',
    //     "path": "",
    // },
    // {
    //     "index": 7,
    //     "name": 'Reporting', 
    //     "icon": '',
    //     "path": "",
    // },
]

export const drawerConsultServices = [
  {
    "index": 1,
    "name": 'Walk Throughs', 
    "icon": <FontAwesomeIcon icon={WalkThroughIcon} />,
    "path": "",
},
{
    "index": 2,
    "name": 'Four Eyes', 
    "icon": <FontAwesomeIcon icon={GlassesIcon} />,
    "path": "",
},
{
    "index": 3,
    "name": 'Data Analysis', 
    "icon": <FontAwesomeIcon icon={ReportingIcon} />,
    "path": "",
},
]

export const drawerCloudServices = [
  {
      "index": 1,
      "name": 'Computation', 
      "icon": <FontAwesomeIcon icon={ComputationIcon} />,
      "path": "",
  },
//   {
//       "index": 2,
//       "name": 'Data', 
//       "icon":  <FontAwesomeIcon icon={DataIcon} />,
//       "path": "",
//   },
  {
      "index": 3,
      "name": 'Reports', 
      "icon": <FontAwesomeIcon icon={ReportingIcon} />,
      "path": "",
  },
//   {
//     "index": 4,
//     "name": 'Sites', 
//     "icon": <FontAwesomeIcon icon={WebIcon} />,
//     "path": "",
// },
]

export const drawerLearningChoices = [
    {
        "index": 1,
        "name": 'Blogs', 
        "icon": <FontAwesomeIcon icon={BlogIcon} />,
        "path": "",
    },
    {
        "index": 2,
        "name": 'YouTube & Twitch', 
        "icon": <FontAwesomeIcon icon={MovieIcon} />,
        "path": "channel",
    },
    {
        "index": 3,
        "name": 'Process Valuations', 
        "icon": <FontAwesomeIcon icon={ProcessIcon} />,
        "path": "",
    },
    {
        "index": 4,
        "name": 'Option Valuations', 
        "icon": <FontAwesomeIcon icon={OptionIcon}/>,
        "path": "/options-pub",
    },
    // {
    //     "index": 5,
    //     "name": 'Other', 
    //     "icon": <FontAwesomeIcon icon={RightDoubleIcon}/>,
    //     "path": "",
    // },
]

export const optionFlavorList = [
    'American',
    'Asian',
    'Barrier',
    'Binary',
    'Chooser',
    'Compound',
    'Double-Barrier',
    'European',
    'Extendible',
    'Future',
    'Lookback',
    'Mountain Range',
    'One-Touch',
    'Perpetual Vanilla',
    'Perpetual Lookback',
    'Power',
    'Quanto Vanilla',
    'Rainbow',
    'Rainbow Minimum',
    'Rainbow Maximum',
    'Russian',
    'Spread',
    'Vanilla'
  ]

  export const optionTypeList = ['All', 'Call', 'Put']

  