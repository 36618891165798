import React from 'react';
// import { withRouter } from 'react-router-dom'
// import Context from './state/Context'

import { MuiThemeProvider } from '@material-ui/core/styles'

import { AppTheme } from './AppTheme'
import AppRoutes from './AppRoutes'
// import AppNav from './AppNav3'
import AppNav from './components/AppNav'
// import Test from './pages/Test'
import './App.css'


const App = () => {
  // const { state } = useContext( Context )
  // console.log('>>>-App-state->', state)
  // console.log('>>>-App-state.userIsknown->', state.userIsknown)
  // console.log('>>>-App-state.userIsAuthenticated->', state.userIsAuthenticated)
  // console.log('>>>-App-state.userForgotPassword->', state.userForgotPassword)  

  return (
    <MuiThemeProvider theme={AppTheme}>
        <header className='App-header'>
          <AppNav />
        </header>

        <main className='App-main'>
          <AppRoutes />
          <br />
          {/* <Test /> */}
        </main>

        <footer className='App-footer'>

        </footer>
    </MuiThemeProvider>
  )
}

export default App;
