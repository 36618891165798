// src/pages/HomeMovieCard.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import {
    Card,
    CardHeader,
    CardContent,
    CardMedia,
    CardActions,
    Collapse,
    Avatar,
    IconButton,
    Typography
} from '@material-ui/core';

import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import HomeMovieMenu from './HomeMovieMenu'

const styles = theme => ({
    card: {
        width: '100%',
        MinHeight: 'auto',
        background: '#EEEEEE',
    },
        cardHeader: {
        background: '#0026ca',
        color: 'white',
    },
        cardAction: {
        background: '#9effff',
    },
        media: {
        width: "370",
        height: "370",
        paddingTop: '100%', // 16:9
    },
        actions: {
        display: 'flex',
        background: '#9effff',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        background: '#0026ca',
        color: 'white',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: '#9effff',
        color: '#0026ca'
    },
});

const HomeMovieCard = (styles) => {
    const [ isExpanded, setIsExpanded ] = useState(false)

    const handleExpandClick = () => {
        setIsExpanded( !isExpanded );
    };

    const { classes } = styles;

    return (
      <Card className={classes.card}>
        <CardHeader className={classes.cardHeader}
            action={
                <HomeMovieMenu />
            }
            title={
                <div style={{color: 'white'}}>
                    Economic Valuation of Processes
                </div>
            }
        >
        </CardHeader>

        <CardContent>
            <CardMedia
            className={classes.media}
            image="/movieScreen.PNG"
            title="Process Movies"
            />
        {/* <img src="ProcessMovies.PNG" alt="Process Movie Screen" width="370" height="370"/> */}
            <Typography variant="subtitle1" gutterBottom>
                Movie title goes here...
            </Typography>
        </CardContent>

        <CardActions className={classes.actions} disableActionSpacing>
          <IconButton aria-label="Add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="Share" >
            <ShareIcon />
          </IconButton>
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: isExpanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={isExpanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>

        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Summary...</Typography>
            <Typography paragraph>
            Summary of movie currently qued/playing movie goes here...
            </Typography>
            <Typography paragraph>
            
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    );
}

HomeMovieCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeMovieCard);