// src/components/AppNav.js

import React, { useContext } from 'react';
import { NavLink, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Context from '../state/Context'
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  Badge,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  InputBase
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faHome as HomeIcon,
    faAddressCard as AboutIcon,
    faUserPlus as SignUpIcon,
    faLockOpen as LockOpenIcon,
    faLock as LockClosedIcon,
    faShoppingCart as ShoppingCartIcon,
    faNotesMedical as NoteIcon,
    faComments as TextAgentIcon,
    faBell as NotificationsIcon
} from "@fortawesome/free-solid-svg-icons";
import { 
    drawerDataChoices,
    drawerConsultServices,
    drawerCloudServices,
    drawerLearningChoices
} from '../utilities/choiceLists'
import { useNavStyles } from '../hooks/useStyles'

import { Auth } from 'aws-amplify';


function AppNav(props) {
    // console.log('>>>-AppNav-props->', props)
    const { state, dispatch } = useContext( Context )
    // console.log('>>>-AppNav-state->', state)
    console.log('>>>-AppNav-state.cartItems->', state.cartItems)
    // console.log('>>>-TopNav-state.user->', state.user)
    // console.log('>>>-AppNav-state.userIsknown->', state.userIsknown)
    // console.log('>>>-AppNav-state.userIsAuthenticated->', state.userIsAuthenticated)
    // console.log('>>>-AppNav-state.userForgotPassword->', state.userForgotPassword) 
    // console.log('>>>-AppNav-state.drawerIsOpen->', state.drawerIsOpen)
    // console.log('>>>-AppNav-state.drawerWidthIs->', state.drawerWidthIs)
    // console.log('>>>-AppNav-state.webMenuIsOpen->', state.webMenuIsOpen)
    // console.log('>>>-AppNav-state.webMenuTarget->', state.webMenuTarget)
    // console.log('>>>-AppNav-state.mobileMenuIsOpen->', state.mobileMenuIsOpen)
    // console.log('>>>-AppNav-state.mobileMenuTarget->', state.mobileMenuTarget)
    // console.log('>>>-AppNav-state.profileIsOpen->', state.profileIsOpen)
    // console.log('>>>-AppNav-state.profileMenuTarget->', state.profileMenuTarget)

    const { classes } = props
    const handleDrawerOpen = () => {
        dispatch({ type: 'DRAWER_IS_OPEN', payload: true });
    };
    const handleDrawerClose = () => {
        dispatch({ type: 'DRAWER_IS_OPEN', payload: false });
    };
    // const handleWebMenuOpen = event => {
    //     console.log('>>>-AppNav-handleProfileMenuOpen-event.currentTarget->', event.currentTarget)
    //     dispatch({ type: 'NAVBAR_WEB_MENU_TARGET', payload: event.currentTarget });
    // };
    const handleWebMenuClose = () => {
        // console.log('>>>-AppNav-handleMenuClose->')
        dispatch({ type: 'NAVBAR_WEB_MENU_TARGET', payload: null });
        handleMobileMenuClose();
    };
    const handleMobileMenuOpen = event => {
        // console.log('>>>-AppNav-handleMobileMenuOpen->',event.currentTarget)
        dispatch({ type: 'NAVBAR_MOBILE_MENU_TARGET', payload: event.currentTarget });
    };
    const handleMobileMenuClose = () => {
        // console.log('>>>-AppNav-handleMobileMenuClose->')
        dispatch({ type: 'NAVBAR_MOBILE_MENU_TARGET', payload: null });
    };
    const handleProfileMenuOpen = event => {
        // console.log('>>>-AppNav-handleProfileMenuOpen->',event.currentTarget)
        dispatch({ type: 'NAVBAR_WEB_MENU_TARGET', payload: event.currentTarget });
    };
    const handleSignOut = async () => {
      await Auth.signOut()
      dispatch({ type: 'USER_SIGN_OUT', payload: null })
      return (<Redirect to='/' />)
    }
    const WebMenu = (  
        <Menu
            anchorEl={state.webMenuTarget}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={state.webMenuIsOpen}
            onClose={handleWebMenuClose}
        >
            <MenuItem onClick={handleWebMenuClose}>
                <Badge badgeContent={state.userDaysToExpiry.toString() + 'd'} color="primary">
                    Account Status
                </Badge>
            </MenuItem>
            <MenuItem onClick={handleWebMenuClose}>Communications</MenuItem>
            <MenuItem onClick={handleWebMenuClose}>Defaults</MenuItem>
            <MenuItem onClick={handleWebMenuClose}>Preferences</MenuItem>
            <MenuItem onClick={handleWebMenuClose}>Profile</MenuItem>
        </Menu>
    );
    const MobileMenu = (
        <Menu
            anchorEl={state.mobileMenuTarget}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={state.mobileMenuIsOpen}
            onClose={handleMobileMenuClose}
        >
        <MenuItem onClick={handleMobileMenuClose}>
        <FontAwesomeIcon icon={LockClosedIcon} style={{padding: 5, color: 'primary'}}/>
            <NavLink to='/' onClick={handleSignOut} style={{color: 'white'}}>
                <Typography style={{padding: 5, color: 'primary'}}>
                Sign Out
                </Typography>
            </NavLink>
        </MenuItem>

        <MenuItem onClick={handleMobileMenuClose}>
            <Badge badgeContent={state.cartItems.length} color="secondary">
                <FontAwesomeIcon icon={ShoppingCartIcon} style={{padding: 5, color: 'primary'}}/>
            </Badge>
            <NavLink to='/' style={{color: 'white'}}>
                <Typography style={{padding: 5, color: 'primary'}}>
                Cart
                </Typography>
            </NavLink>
        </MenuItem>

        <MenuItem onClick={handleMobileMenuClose}>
            <Badge badgeContent={state.notes.length} color="secondary">
                <FontAwesomeIcon icon={NoteIcon} style={{padding: 5, color: 'primary'}}/>
            </Badge>
            <NavLink to='/' style={{color: 'white'}}>
                <Typography style={{padding: 5, color: 'primary'}}>
                Notes
                </Typography>
            </NavLink>
        </MenuItem>

        <MenuItem onClick={handleMobileMenuClose}>
            <Badge badgeContent={state.userUnreadComms.length} color="secondary">
                <FontAwesomeIcon icon={NotificationsIcon} style={{padding: 5, color: 'primary'}}/>
            </Badge>
            <NavLink to='/' style={{color: 'white'}}>
                <Typography style={{padding: 5, color: 'primary'}}>
                Notifications
                </Typography>
            </NavLink>
        </MenuItem>

        <MenuItem onClick={handleProfileMenuOpen} >
            <IconButton color="inherit" >
                <AccountCircle />
            </IconButton>
            <p>Profile</p>
        </MenuItem>
    </Menu>
    )
    const DrawerMenu = (
        <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={state.drawerIsOpen}
        classes={{
            paper: classes.drawerPaper,
        }}
        >
        <div className={classes.drawerHeader}>
            <IconButton 
                onClick={handleDrawerClose}  
                style={{background: '#0026ca', margin: 5}}>
                {state.drawerIsOpen ? 
                <ChevronLeftIcon style={{color: 'white'}} /> : 
                <ChevronRightIcon />}
            </IconButton>
        </div>
        
        <ExpansionPanel align='center' style={{color: '#0026ca', background: '#9effff'}}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{margin: 0, paddingLeft: 5}}>
          <Typography>
            Analytica Process
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{margin: 0, padding: 5, background: 'white'}}>
        <List >
            {drawerDataChoices.map(item => (
            <ListItem 
            button key={item.index} 
            onClick={handleDrawerClose}
            style={{margin: 0, padding: 2, background: 'white'}}>
                {
                    item.icon ? 
                    <ListItemIcon>{
                    item.icon
                    }</ListItemIcon> :
                    '-'
                }
                <NavLink to={item.path} style={{ textDecoration: 'none', marginLeft: 2, padding: 0, align: 'left'}}>
                <Button type="button" style={{ textDecoration: 'none', marginLeft: 2, padding: 4, align: 'left'}}>
                    <Typography align='center' style={{color: '#0026ca', fontSize: 12, marginLeft: 2, padding: 2, align: 'left'}} >
                    {item.name}
                    </Typography>
                </Button>
                </NavLink>
            </ListItem>
            ))}
        </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

        <ExpansionPanel align='center' style={{color: '#0026ca', background: '#9effff'}}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{margin: 0, paddingLeft: 5}}>
          <Typography>
          Consulting Services
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{margin: 0, padding: 5, background: 'white'}}>
        <List >
            {drawerConsultServices.map(item => (
            <ListItem 
            button key={item.index} 
            onClick={handleDrawerClose}
            style={{margin: 0, padding: 2, background: 'white'}}>
                {
                    item.icon ? 
                    <ListItemIcon>{
                    item.icon
                    }</ListItemIcon> :
                    '-'
                }
                <NavLink to={item.path} style={{ textDecoration: 'none', marginLeft: 2, padding: 0, align: 'left'}}>
                <Button type="button" style={{ textDecoration: 'none', marginLeft: 2, padding: 4, align: 'left'}}>
                    <Typography align='center' style={{color: '#0026ca', fontSize: 12, marginLeft: 2, padding: 2, align: 'left'}} >
                    {item.name}
                    </Typography>
                </Button>
                </NavLink>
            </ListItem>
            ))}
        </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

        <ExpansionPanel align='center' style={{color: '#0026ca', background: '#9effff'}}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{margin: 0, paddingLeft: 5}}>
          <Typography>
          Cloud Services
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{margin: 0, padding: 5, background: 'white'}}>
        <List >
            {drawerCloudServices.map(item => (
            <ListItem 
            button key={item.index} 
            onClick={handleDrawerClose}
            style={{margin: 0, padding: 2, background: 'white'}}>
                {
                    item.icon ? 
                    <ListItemIcon>{
                    item.icon
                    }</ListItemIcon> :
                    '-'
                }
                <NavLink to={item.path} style={{ textDecoration: 'none', marginLeft: 2, padding: 0, align: 'left'}}>
                <Button type="button" style={{ textDecoration: 'none', marginLeft: 2, padding: 4, align: 'left'}}>
                    <Typography align='center' style={{color: '#0026ca', fontSize: 12, marginLeft: 2, padding: 2, align: 'left'}} >
                    {item.name}
                    </Typography>
                </Button>
                </NavLink>
            </ListItem>
            ))}
        </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel align='center' style={{color: '#0026ca', background: '#9effff'}}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{margin: 0, paddingLeft: 5}}>
          <Typography>
          Learning Center
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{margin: 0, padding: 5, background: 'white'}}>
        <List >
            {drawerLearningChoices.map(item => (
            <ListItem 
            button key={item.index} 
            onClick={handleDrawerClose}
            style={{margin: 0, padding: 2, background: 'white'}}>
                {
                    item.icon ? 
                    <ListItemIcon>{
                    item.icon
                    }</ListItemIcon> :
                    '-'
                }
                <NavLink to={item.path} style={{ textDecoration: 'none', marginLeft: 2, padding: 0, align: 'left'}}>
                <Button type="button" style={{ textDecoration: 'none', marginLeft: 2, padding: 4, align: 'left'}}>
                    <Typography align='center' style={{color: '#0026ca', fontSize: 12, marginLeft: 2, padding: 2, align: 'left'}} >
                    {item.name}
                    </Typography>
                </Button>
                </NavLink>
            </ListItem>
            ))}
        </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

        <Divider />
        <Divider />
    </Drawer>
    )

  return (
    <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton 
            className={classes.menuButton} 
            onClick={handleDrawerOpen}
            color="inherit" 
            aria-label="Open drawer">
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              AITO Consulting
            </Typography>

            {state.userIsAuthenticated && 
            <>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>

            <div className={classes.grow} />

            <MenuItem onClick={handleMobileMenuClose}>
                <NavLink to='/home' style={{color: 'white'}}>
                    <FontAwesomeIcon icon={HomeIcon} style={{padding: 5, color: 'white'}} />
                </NavLink>
            </MenuItem>

            <div className={classes.sectionDesktop}>

                <NavLink to='/' style={{color: 'white'}}>
                    <IconButton color="inherit">
                        <Badge badgeContent={state.cartItems.length} color="secondary">
                        <FontAwesomeIcon icon={ShoppingCartIcon} style={{padding: 5, color: 'white'}}/>
                        </Badge>
                    </IconButton>
                </NavLink>
               <NavLink to='/' style={{color: 'white'}}>
                <IconButton color="inherit">
                    <Badge badgeContent={state.notes.length} color="secondary">
                    <FontAwesomeIcon icon={NoteIcon} style={{padding: 5, color: 'white'}}/>
                    </Badge>
                </IconButton>
            </NavLink>
            <NavLink to='/' style={{color: 'white'}}>
                <IconButton color="inherit">
                    <Badge badgeContent={state.textMessages.length} color="secondary">
                    <FontAwesomeIcon icon={TextAgentIcon} style={{padding: 5, color: 'white'}}/>
                    </Badge>
                </IconButton>
            </NavLink>
            <NavLink to='/' style={{color: 'white'}}>
                <IconButton color="inherit">
                    <Badge badgeContent={state.userUnreadComms.toString()} color="secondary">
                    <FontAwesomeIcon icon={NotificationsIcon} style={{padding: 5, color: 'primary'}}/>
                    </Badge>
                </IconButton>
            </NavLink>
                <NavLink to='/' onClick={handleSignOut} style={{color: 'white'}}>
                    <IconButton color="inherit">
                    <FontAwesomeIcon icon={LockClosedIcon} style={{padding: 5, color: 'primary'}}/>
                    </IconButton>
                </NavLink>
                <IconButton
                    aria-owns={state.menuIsOpen ? 'material-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
            </div>
            <div className={classes.sectionMobile}>
            <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
                <MoreIcon />
            </IconButton>
            </div>
            </>}

            {!state.userIsAuthenticated &&
            <>
            <div className={classes.grow} />
           
            <MenuItem onClick={handleMobileMenuClose}>
            <FontAwesomeIcon icon={AboutIcon} style={{padding: 5, color: 'white'}} />
                <NavLink to='/about' style={{color: 'white', textDecoration: 'none'}}>
                    <Typography style={{padding: 5, color: 'white'}}>
                    About
                    </Typography>
                </NavLink>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
            <FontAwesomeIcon icon={SignUpIcon} style={{padding: 5, color: 'white'}} />
                <NavLink to='/signup' style={{color: 'white'}}>
                    <Typography style={{padding: 5, color: 'white'}}>
                    Sign Up
                    </Typography>
                </NavLink>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
            <FontAwesomeIcon icon={LockOpenIcon} style={{padding: 5, color: 'white'}}/>
                <NavLink to='/signin' style={{color: 'white'}}>
                    <Typography style={{padding: 5, color: 'white'}}>
                    Sign In
                    </Typography>
                </NavLink>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
                <NavLink to='/home' style={{color: 'white'}}>
                    <FontAwesomeIcon icon={HomeIcon} style={{padding: 5, color: 'white'}} />
                </NavLink>
            </MenuItem>
            </>
            }
            
            {state.userIsAuthenticated && WebMenu}
            {state.userIsAuthenticated && MobileMenu}
            {state.userIsAuthenticated && DrawerMenu}

          </Toolbar>
        </AppBar>

        {/* <main
            className={classNames(classes.content, {
            [classes.contentShift]: state.drawerIsOpen,
            })}   
        >
            <Typography paragraph >
            
            </Typography>
        </main> */}
    
    </div>
  );
}

AppNav.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// export default AppNav
export default withStyles(useNavStyles, { withTheme: true })(AppNav);
