// src/components/HomeMovieMenu.js

import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const choices = [
    'Revenue to Cash',
    '1 start : 1',
    '1 start : n',
    'n start : 1',
    'n start : n',
    '1 : 1',
    '1 : n',
    'n : 1',
    'n : n',
    '1 : stop',
    'n : stop',
    'Process valuation',
    'Option valuation',
    'Manage macro to micro',
    'Control micro to macro',
];

const ITEM_HEIGHT = 48;

const HomeMovieMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null)
  
    const handleClick = event => {
        console.log('>>>-HomeMovieMenu-event->',event)
        console.log('>>>-HomeMovieMenu-event.currentTarget->',event.currentTarget)
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl( null );
    };
  
    return (
        <div>
            <IconButton
            aria-label="More"
            aria-owns={Boolean(anchorEl) ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            >
                <MoreVertIcon style={{color: 'white'}}/>
            </IconButton>
            <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
                style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 250,
                },
            }}
            >
            {choices.map(chocie => (
                <MenuItem 
                key={chocie} 
                selected={chocie === 'Revenue to Cash'} 
                onClick={handleClose}>
                {chocie}
                </MenuItem>
            ))}
            </Menu>
        </div>
    );
  }
  
  export default HomeMovieMenu;
