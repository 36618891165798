// src/auth/SignIn.js
import React, { useContext } from 'react'
import Context from '../state/Context'
import { Link } from 'react-router-dom'
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  CardActions,
  Button,
  Typography,
  TextField
} from "@material-ui/core";

import { useInput } from "../hooks/useInput";
import { useSubmit } from "../hooks/useSubmit";
import { validations } from "../utilities/validations.js";

import { useSignInStyles } from '../hooks/useStyles'


const SignIn = (props) => {
    const classes = useSignInStyles()
    // console.log('>>>-SignIn-props->', props)
    const { dispatch } = useContext( Context )
    // console.log('>>>-SignIn-state->', state)
    // console.log('>>>-SignIn-state.userIsknown->', state.userIsknown)
    // console.log('>>>-SignIn-state.userIsAuthenticated->', state.userIsAuthenticated)
    // console.log('>>>-SignIn-state.userForgotPassword->', state.userForgotPassword)

    // (name, type, defaultValue, regex)
    const email = useInput("email", "email", "", validations.EMAIL);
    const password = useInput("password", "password", "", validations.PASSWORD);

    const { 
      // values, 
      errors, 
      isSubmitting, 
      handleSignIn 
    } = useSubmit(
      signin,// callback
      props,
      {email, password},
      dispatch
  )

  function signin () {
    // console.log('>>>-SignIn-signin-values->',values)
    // console.log('>>>-SignIn-signin-errors->',errors)
  }

  return (
<Grid container className={classes.grid} spacing={16}>
<Grid item xs={12}>
<Grid container className={classes.demo} justify="center" spacing={Number(16)}>
    <Card className={classes.card}>
   
      <CardHeader className={classes.cardHeader}
        avatar={
          <Avatar aria-label="Recipe" className={classes.avatar}>
           
          </Avatar>
        }
        // action={
        //   <IconButton>
        //   {/* <MoreVertIcon /> */}
        //   </IconButton>
        // }
        title={
          <div style={{color: 'white'}}>
              AITO Consulting
          </div>
        }
      >

      </CardHeader>
      <CardContent>
      
      {/* <Paper className={classes.paper}> */}
      <Typography 
        variant="h5" 
        style={{background: '#304ffe', color: 'white', padding: 5}}
        >
        Sign In Form
      </Typography>
      {/* </Paper> */}
      <form 
        onSubmit={handleSignIn}
        className={classes.form} 
      >
        <div className={classes.input} style={{padding: 5}}>
          <TextField 
            label="Email" 
            name='email' 
            type='email' 
            {...email.props}
            required
            variant="outlined" 
            fullWidth={true}
          />
          {email.props.error && (
            <Typography variant="body1" color="error">
              Invalid email
            </Typography>
          )}
        </div>
        <div className={classes.input} style={{padding: 5}}>
          <TextField 
          label="Password" 
          name='password' 
          type='password' 
          {...password.props}
          required
          variant="outlined" 
          fullWidth={true}
          />
          {password.props.error && (
            <Typography variant="body1" color="error">
              Minimum 8 charaters.
            </Typography>
          )}
        </div>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={ !email.props.value || !password.props.value }
        >
          {isSubmitting ? 'Signing In...' : 'Sign In'}
        </Button>
        <br/><br/>
        {errors && errors.length > 0 && (
          <Typography variant="body1" color="error">
            {`Please fix ${errors && errors.length} form
          field error(s)`}
          </Typography>
        )}
        <Link to='/signin/forgot'>Forgot your password?</Link>
        <p style={{color: '#0026ca'}}>We'll send you an email.</p>
        </form>
      <CardActions className={classes.actions} disableActionSpacing>

      </CardActions>
      </CardContent>
    </Card>
  </Grid>
  </Grid>
  </Grid>

   
  )
}

export default SignIn

