// src/pages/About.js
import React from 'react'

const About = () => {

    return (
        <h3>About</h3>
    )
}

export default About