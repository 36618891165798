// src/pages/options/OptionForm.js
import React, { useContext } from 'react'
import Context from '../../state/Context'
import { optionFlavorList, optionTypeList } from '../../utilities/choiceLists'
import { useInput } from "../../hooks/useInput";
import { useSubmit } from "../../hooks/useSubmit";
import { validations } from "../../utilities/validations.js";

import { useOptionsStyles } from '../../hooks/useStyles'
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  CardActions,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem
} from "@material-ui/core";

import Greeks from './Greeks'


const OptionForm = (props) => {
    const classes = useOptionsStyles()
    const selectLabelWidth = 125
    // console.log('>>>-OptionForm-props->', props)
    const { dispatch } = useContext( Context )
    // console.log('>>>-OptionForm-state->', state)
    // console.log('>>>-OptionForm-state.userIsknown->', state.userIsknown)
    // console.log('>>>-OptionForm-state.userIsAuthenticated->', state.userIsAuthenticated)
    // console.log('>>>-OptionForm-state.userForgotPassword->', state.userForgotPassword)

    // (name, type, defaultValue, regex)
    const optionFlavor = useInput('optionFlavor', 'text', '', validations.ANYTHING)
    const optionType = useInput('optionType', 'text', '', validations.ANYTHING)
    const underlying = useInput("underlying", "text", "", validations.REAL_GE0)
    const strike = useInput("strike", "text", "", validations.REAL_GE0)
    const volatility = useInput('volatility', 'text', '', validations.REAL_GE0)
    const expiry = useInput('expiry', 'text', '', validations.REAL_GE0)
    const dividendRate = useInput('dividendRate', 'text', '', validations.REAL_GE0)
    const riskFreeRate = useInput('riskFreeRate', 'text', '', validations.REAL_GE0)

    const { 
      results,
      errors, 
      isSubmitting, 
      handleCalcOptionVals 
    } = useSubmit(
      optionVals,// callback
      props,
      {
        optionFlavor, 
        optionType, 
        underlying, 
        strike, 
        volatility, 
        expiry, 
        dividendRate, 
        riskFreeRate
      },
      dispatch
  )

  function optionVals () {
    // console.log('>>>-OptionForm-optionVals-values->',values)
    // console.log('>>>-OptionForm-optionVals-errors->',errors)
  }

  // console.log('>>>-OptionForm-values->',values)
  // console.log('>>>-OptionForm-results->',results)  

  return (
    <Grid container className={classes.grid} spacing={16}>
    <Grid item xs={12}>
    <Grid container className={classes.demo} justify="center" spacing={Number(16)}>
    <Card className={classes.card}>
   
      <CardHeader className={classes.cardHeader}
        avatar={
          <Avatar aria-label="Recipe" className={classes.avatar}>
           {'>-<'}
          </Avatar>
        }
        // action={
        //   <IconButton>
        //   {/* <MoreVertIcon /> */}
        //   </IconButton>
        // }
        title={
          <div style={{color: 'white'}}>
              Option Valuation
          </div>
        }
      >

      </CardHeader>
      <CardContent>
      
      {/* <Paper className={classes.paper}> */}
      {/* <Typography 
        variant="h5" 
        style={{background: '#304ffe', color: 'white', padding: 5}}
        >
        Enter parameters
      </Typography> */}
      {/* </Paper> */}
      <form 
        onSubmit={handleCalcOptionVals}
        className={classes.form} 
      >
        <div className={classes.input} style={{padding: 1}}>
            <FormControl
              variant='outlined'
              required
              className={classes.formControl}
            >
            <InputLabel 
            htmlFor='outlined-optionFlavor-simple' 
            // style={{fontSize: 'small'}}
            >
              Option Flavor
            </InputLabel>
              <Select
                {...optionFlavor.props}
                input={
                  <OutlinedInput
                    labelWidth={selectLabelWidth}
                    // style={{fontSize: 'small'}}
                    name='optionType'
                    id='outlined-optionType-simple'
                  />
                }
              >
                <MenuItem value='' style={{fontSize: 'small', margin: 0, padding:0}}>
                  <em>None</em>
                </MenuItem>
                {optionFlavorList.map(optionFlavor => (
                  <MenuItem
                    key={optionFlavor}
                    value={optionFlavor}
                    style={{fontSize: 'small', margin: 0, padding:0}}
                    disabled={
                      !(
                        optionFlavor === 'American' ||
                        optionFlavor === 'European'
                      )
                    }
                  >
                    {optionFlavor}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant='outlined'
              required
              className={classes.formControl}
            >
            <InputLabel 
            htmlFor='outlined-optionFlavor-simple' 
            // style={{fontSize: 'small'}}
            >
              Option Type
            </InputLabel>
              <Select
                {...optionType.props}
                input={
                  <OutlinedInput
                    labelWidth={selectLabelWidth}
                    // style={{fontSize: 'small'}}
                    name='optionType'
                    id='outlined-optionType-simple'
                  />
                }
              >
                <MenuItem value="" style={{fontSize: 'small', margin: 0, padding:0}}>
                  <em>None</em>
                </MenuItem>
                {optionTypeList.map(optionType => (
                  <MenuItem 
                  key={optionType} 
                  value={optionType}
                  style={{fontSize: 'small', margin: 0, padding:0}}
                  >
                    {optionType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          
          <TextField 
            className={classes.formControl}
            label="Underlying (u)" 
            name='underlying' 
            type='text' 
            {...underlying.props}
            required
            variant="outlined"
          />
          {underlying.props.error && (
            <Typography variant="body1" color="error">
              Invalid number
            </Typography>
          )}
        
          <TextField 
          className={classes.formControl}
          label="Strike (k)" 
          name='strike' 
          type='strike' 
          {...strike.props}
          required
          variant="outlined" 
          />
          {strike.props.error && (
            <Typography variant="body1" color="error">
            Invalid number
            </Typography>
          )}
  
          <TextField
            className={classes.formControl}
            label='Volatility % (v)'
            name='volatility'
            type='text' 
            {...volatility.props}
            required
            variant="outlined" 
          />
          {volatility.props.error && (
            <Typography variant='body1' color='error'>
              Invalid quantity
            </Typography>
          )}
    
          <TextField
            className={classes.formControl}
            label='Days to Expiry (x)'
            name='expiry'
            type='text' 
            {...expiry.props}
            required
            variant="outlined" 
          />
          {expiry.props.error && (
            <Typography variant='body1' color='error'>
              Invalid quantity
            </Typography>
          )}
     
          <TextField
            className={classes.formControl}
            label='Div. Rate % (d)'
            name='dividendRate'
            type='text' 
            {...dividendRate.props}
            required
            variant="outlined" 
          />
          {dividendRate.props.error && (
            <Typography variant='body1' color='error'>
              Invalid quantity
            </Typography>
          )}
     
          <TextField
            className={classes.formControl}
            label='Riskless Rate % (r)'
            name='riskFreeRate'
            type='text' 
            {...riskFreeRate.props}
            required
            variant="outlined" 
          />
          {riskFreeRate.props.error && (
            <Typography variant='body1' color='error'>
              Invalid quantity
            </Typography>
          )}
        </div>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={ 
            !optionFlavor.props.value ||
            !optionType.props.value ||
            !underlying.props.value ||
            !strike.props.value ||
            !volatility.props.value ||
            !expiry.props.value ||
            !dividendRate.props.value ||
            !riskFreeRate.props.value
           }
        >
          {isSubmitting ? 'Calculating...' : 'Calculate'}
        </Button>
        <br/><br/>
        {errors && errors.length > 0 && (
          <Typography variant="body1" color="error">
            {`Please fix ${errors && errors.length} form
          field error(s)`}
          </Typography>
        )}
        
        </form>

        {Boolean(results) ? <Greeks results={results} /> : null}

      <CardActions className={classes.actions} disableActionSpacing>

      </CardActions>

      </CardContent>
    </Card>
    </Grid>
    </Grid>
    </Grid>

   
  )
}

export default OptionForm

