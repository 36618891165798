// src/pages/ReadMore.js
import React from 'react'

const ReadMore = () => {

    return (
        <h3>ReadMore</h3>
    )
}

export default ReadMore