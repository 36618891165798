// src/utilities/validations.js
//
// https://regexr.com/
// https://www.regextester.com/
//

export const validations = {
    // eslint-disable-next-line
    NOTHING: /^(.*)/,
    ANYTHING: /^(.+)/,
    EMAIL: /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PASSWORD: /^(.+){8}$/,
    USA_STATE_ABRV: /^[A-Z]{2}$/,
    PHONE_USA: /^\([\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/,
    // 2223334444 | 222-333-4444 | (222)-333-4444
    // PHONE_USA: /^\(?[\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/,
    // 12223334444 | 1-222-333-4444 | 1-(222)-333-4444
    YEAR: /^(19|20)[\d]{2,2}$/,
    // Year 1900-2099
    DATE: /^([1-9]|0[1-9]|[12][0-9]|3[01])\D([1-9]|0[1-9]|1[012])\D(19[0-9][0-9]|20[0-9][0-9])$/,
    // Date (dd mm yyyy, d/m/yyyy, etc.):
    INTEGER: /^-?\d*$/,
    INTEGER_GE0: /^\d*$/,
    INTEGER_LE0: /^-\d*$/,
    REAL: /^-?\d+((,\d+)+)?(.\d+)?(.\d+)?(,\d+)?/,
    REAL_GE0: /^\d+((,\d+)+)?(.\d+)?(.\d+)?(,\d+)?/,
    REAL_LE0: /^-\d+((,\d+)+)?(.\d+)?(.\d+)?(,\d+)?/
  }
  