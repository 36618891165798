// src/components/WalkThroughs.js
import React from 'react'

const WalkThroughs = () => {

    return (
        <h3>WalkThroughs</h3>
    )
}

export default WalkThroughs