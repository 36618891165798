// src/AppTheme.js

import { createMuiTheme } from '@material-ui/core/styles'
// import lightBlue from '@material-ui/core/colors/lightBlue';
// import teal from '@material-ui/core/colors/teal';
// import red from '@material-ui/core/colors/red';
// import indigo from 'material-ui/colors/indigo';

export const AppTheme = createMuiTheme({
    palette: {
        primary: {
          light: '#7b7cff',
          main: '#304fff',
          dark: '#0026ca',
          contrastText: '#fff',
        },
        secondary: {
          light: '#baffff',
          main: '#84ffff',
          dark: '#4bcbcc',
          contrastText: '#000',
        },
        status: {
          clear: '#9cff57',
          caution: '#ffff56',
          danger: '#ff5983',
          error: '#c67c00',
        },
    },
    typography: {
        useNextVariants: true,
        fontSize: 10,
        htmlFontSize: 10,
    },
    overrides: {
      
    },
})
 