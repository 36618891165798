// src/components/Dashboard.js
import React from 'react'

const Dashboard = () => {

    return (
        <h3>Dashboard</h3>
    )
}

export default Dashboard