// src/auth/Account.js
import React from 'react'

const Account = () => {

    return (

        <h3>Account</h3>

    )
}

export default Account