// src/state/Reducer.js

export default function Reducer (state, { type, payload }) {
    // console.log('>>>-Reducer-state->', state)
    // console.log('>>>-Reducer-type->', type)
    // console.log('>>>-Reducer-payload->', payload)
    switch (type) {
        case 'USER_SIGN_IN': 
        return { 
                ...state, 
                session: payload, 
                userIsknown: true,
                userIsAuthenticated: true,
                // localToken...
                drawerIsOpen: false,
                webMenuIsOpen: false,
                mobileMenuIsOpen: false,
                profileIsOpen: false
            }
        case 'USER_SIGN_UP': 
        return { 
                ...state, 
                session: payload, 
                userIsknown: true,
                userIsAuthenticated: false
            }
        case 'USER_SIGN_UP_CONFIRM': 
        return { 
                ...state, 
                session: payload,
                userIsknown: true,
                userIsAuthenticated: true,
                // localToken...
                drawerIsOpen: false,
                webMenuIsOpen: false,
                mobileMenuIsOpen: false,
                profileIsOpen: false
            }
        case 'USER_SIGN_OUT': 
        return {
                ...state, 
                session: payload, 
                userIsknown: false,
                userIsAuthenticated: false
            }
        case 'USER_FORGOT_PASSWORD': 
        return { 
                ...state, 
                session: payload,
                userForgotPassword: true,
                userIsAuthenticated: false
            }
        case 'USER_FORGOT_PASSWORD_CONFIRM': 
        return { 
                ...state, 
                session: payload,
                userForgotPassword: false,
                userIsAuthenticated: true
            }
        case 'USER_CHANGE_EMAIL': 
        return { 
                ...state, 
                session: payload,
                userChangeEmail: true
            }
        case 'USER_CHANGE_EMAIL_CONFIRM': 
        return { 
            ...state, 
            userChangeEmail: false,
            userIsAuthenticated: true
            }
        case 'DRAWER_IS_OPEN': 
        return { 
                ...state, 
                drawerIsOpen: payload,
            }
        case 'NAVBAR_WEB_MENU_TARGET':
        return {
                ...state, 
                webMenuTarget: payload,
                webMenuIsOpen: Boolean(payload)
            }
        case 'NAVBAR_MOBILE_MENU_TARGET':
        return {
                ...state, 
                mobileMenuTarget: payload,
                mobileMenuIsOpen: Boolean(payload)
            }
        case 'NAVBAR_PROFILE_TARGET':
        return {
                ...state, 
                profileMenuTarget: payload,
                profileIsOpen: Boolean(payload)
            }

        default: 
        return state
    }
}