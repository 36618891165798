// src/components/AppRoutes

import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
// import Context from './state/Context'
import PrivateRoute from './state/PrivateRoute'

import SignIn from './auth/SignIn'
import SignUp from './auth/SignUp'
import Account from './auth/Account'
import NotFound from './auth/NotFound'
import Dashboard from './components/Dashboard'
import WalkThroughs from './components/WalkThroughs'
import Home from './pages/home/Home'
import LiveCoding from './pages/liveCoding/LiveCoding'
import About from './pages/About'
import OptionForm from './pages/options/OptionForm'
import ReadMore from './pages/ReadMore'
import Channel from './pages/channel/Channel'
// import Shop from './store/Shop'
import Projects from './pages/Projects'

const AppRoutes = (props) => {
  // console.log('>>>-AppRoutes-props->', props)
  // const { state } = useContext( Context )
  // console.log('>>>-AppRoutes-state->', state)
  // console.log('>>>-AppRoutes-state.user->', state.user)
  // console.log('>>>-AppRoutes-state.userIsknown->', state.userIsknown)
  // console.log('>>>-AppRoutes-state.userIsAuthenticated->', state.userIsAuthenticated)
  // console.log('>>>-AppRoutes-state.userForgotPassword->', state.userForgotPassword) 

  return (
    <Switch>
      <Route exact path='/signin' component={SignIn} />
      <Route exact path='/signup' component={SignUp} />
      <Route exact path='/home' component={Home} />
      <Route exact path='/live-coding' component={LiveCoding} />
      <Route exact path='/about' component={About} />
      <Route exact path='/options-pub' component={OptionForm} />
      <Route exact path='/read-more' component={ReadMore} />
      <PrivateRoute exact path='/projects' component={Projects} />
      {/* <PrivateRoute exact path='/shop' component={Shop} /> */}
      <PrivateRoute exact path='/walk-throughs' component={WalkThroughs} />
      <PrivateRoute exact path='/channel' component={Channel} />
      <PrivateRoute exact path='/account' component={Account} />
      <PrivateRoute exact path='/dashboard' component={Dashboard} />
{/* 
      <PrivateRoute exact path='/account-status' component={AccountStatus} />
      <PrivateRoute exact path='/profile' component={Profile} />
      <PrivateRoute exact path='/blogs' component={Blogs} />
      <PrivateRoute exact path='/youtube' component={youtube} />
      <PrivateRoute exact path='/courses' component={Courses} />
      <PrivateRoute exact path='/github' component={GitHub} />
      <PrivateRoute exact path='/presentations' component={Presentations} />
      <PrivateRoute exact path='/other' component={Other} /> 
*/}

      <Route exact path='/' component={Home} />
      <Route path='/not-found' component={NotFound} />
      {/* everything else goes to Splash */}
      <Route render={function () {
        return  <Redirect to='/' />
      }} />
    </Switch>
  )
}

export default AppRoutes