// src/pages/optionValuation/Greeks.js

import React from 'react'
// import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 1,
    overflowX: 'auto'
  },
  table: {
    minWidth: 500
  },
  tableHeader: {
    align: 'center',
    fontSize: 'medium',
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  tableCell: {
    align: 'center',
    fontSize: 'small',
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
})

function Greeks (props) {
  // console.log('>>>-Greeks-props->', props)
  const { classes, results } = props
  // console.log('>>>-Greeks-classes->', classes)
  // console.log('>>>-Greeks-results->', results)

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Type</TableCell>
            <TableCell className={classes.tableHeader}>TV</TableCell>
            <TableCell className={classes.tableHeader}>Delta</TableCell>
            <TableCell className={classes.tableHeader}>Gamma</TableCell>
            <TableCell className={classes.tableHeader}>Theta</TableCell>
            <TableCell className={classes.tableHeader}>Vega</TableCell>
            <TableCell className={classes.tableHeader}>Rho</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map(option => (
            <TableRow key={option.id}>
              <TableCell className={classes.tableCell}>{option.Type}</TableCell>
              <TableCell className={classes.tableCell}>{option.TV}</TableCell>
              <TableCell className={classes.tableCell}>{option.Delta}</TableCell>
              <TableCell className={classes.tableCell}>{option.Gamma}</TableCell>
              <TableCell className={classes.tableCell}>{option.Theta}</TableCell>
              <TableCell className={classes.tableCell}>{option.Vega}</TableCell>
              <TableCell className={classes.tableCell}>{option.Rho}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

// Greeks.propTypes = {
//   classes: PropTypes.object.isRequired
// }

export default withStyles(styles)(Greeks)
