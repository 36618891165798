// src/pages/Home.js
import React, { useContext } from 'react'
import Context from '../../state/Context'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { 
    Grid,
    Card,
    CardHeader,
    Avatar,
    CardContent,
    Typography,
    CardActions,
    IconButton,
    MenuItem
} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import { withStyles } from '@material-ui/core/styles'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    // faAddressCard as AboutIcon,
    faUserMd as ServiceStackIcon,
    faEnvelopeOpen as EmailIcon,
    faBlog as BlogIcon,
    faFileCode as FileCodeIcon
} from "@fortawesome/free-solid-svg-icons";

import HomeInfoCard from './HomeInfoCard'
import HomeMovieCard from './HomeMovieCard'

// const drawerWidth = 200;
const styles = theme => ({
    root: {
        display: 'flex',
        width: '90%',
      },
    grid: {
        flexGrow: 1,
        marginTop: 5,
    },
    card: {
        padding: theme.spacing.unit * 2,
        background: '#e3f2fd',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        opacity: .85,
    },
    cardHeader: {
        background: '#0026ca',
        color: 'white',
      },
    cardActions: {
        background: '#304fff',
    },
    avatar: {
        backgroundColor: '#9effff',
        color: '#0026ca',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(10000 + theme.spacing.unit * 2 * 2)]: {
          width: 10000,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
    cardGrid: {
        padding: `${theme.spacing.unit * 5}px 0`,
    },
  });



function Home(props) {
    const { state } = useContext( Context )
    const { classes } = props;

    return (
        <div className={classes.root}>
            <Grid container spacing={24} >
            <Grid item xs={12} >
            
            <Card className={classes.card}>
            <CardHeader className={classes.cardHeader}
                avatar={
                    <Avatar aria-label="Recipe" className={classes.avatar}>
                    MS
                    </Avatar>
                }
                title={
                    <div style={{color: 'white', fontSize: 32}}>
                        AITO Consutling
                    </div>
                }
            >
            </CardHeader>
            <div className={classes.heroUnit}>
                <div className={classes.heroContent}>
                    <CardActions className={classes.cardActions} disableActionSpacing>

                    <IconButton aria-label="Add to favorites" style={{color: 'white', padding: 5}}>
                        <FavoriteIcon />
                    </IconButton>
                    <IconButton aria-label="Share" style={{color: 'white', paddingRight: 100}}>
                        <ShareIcon />
                    </IconButton>

                    <MenuItem>
                    <FontAwesomeIcon icon={ServiceStackIcon} style={{padding: 5, color: 'white'}} />
                        <NavLink to='/services' style={{color: 'white', textDecoration: 'none'}}>
                            <Typography variant='h6' style={{padding: 5, color: 'white'}}>
                            Services
                            </Typography>
                        </NavLink>
                    </MenuItem>

                    <MenuItem>
                    <FontAwesomeIcon icon={FileCodeIcon} style={{padding: 5, color: 'white'}} />
                        <NavLink to='/live-coding' style={{color: 'white', textDecoration: 'none'}}>
                            <Typography variant='h6' style={{padding: 5, color: 'white'}}>
                            Live coding
                            </Typography>
                        </NavLink>
                    </MenuItem>

                    {/* <MenuItem>
                    <FontAwesomeIcon icon={AboutIcon} style={{padding: 5, color: 'white'}} />
                        <NavLink to='/about' style={{color: 'white', textDecoration: 'none'}}>
                            <Typography variant='h6' style={{padding: 5, color: 'white'}}>
                            About
                            </Typography>
                        </NavLink>
                    </MenuItem> */}

                    <MenuItem>
                    <FontAwesomeIcon icon={BlogIcon} style={{padding: 5, color: 'white'}} />
                        <NavLink to='/blog' style={{color: 'white', textDecoration: 'none'}}>
                            <Typography variant='h6' style={{padding: 5, color: 'white'}}>
                            Blog
                            </Typography>
                        </NavLink>
                    </MenuItem>

                    <MenuItem>
                    <FontAwesomeIcon icon={EmailIcon} style={{padding: 5, color: 'white'}} />
                        <NavLink to='/contact-us' style={{color: 'white', textDecoration: 'none'}}>
                            <Typography variant='h6' style={{padding: 5, color: 'white'}}>
                            Contact us
                            </Typography>
                        </NavLink>
                    </MenuItem>

                    </CardActions>

                    <CardContent>
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                        Service Offering
                        </Typography>
                        <Typography variant="h6" align="center" color="textSecondary" paragraph>
                        Analysis and modeling of cashflows: operational controls, financial valuation and risks.  
                        </Typography>
                        <hr/>
                        <Typography justify="left" align="left">
                        Cash-flows are derived from operational processes associated with internal controls, external contractual arrangements and accounting requirements.
                        </Typography>
                        <br/>
                        <Typography justify="left" align="left">
                        One set of questions revolve around revenue recognition.  
                        For instance, given a service contract, and a revenue recognition method, how much revenue should be recognized when and with what reserve requirement?  
                        This is a multidimensional macro question that presumes foresight.
                        </Typography>
                        <br/>
                        <Typography justify="left" align="left">
                        We help you leverage your operational data to illuminate the possibilities.
                        </Typography>
                        <br/>

                        <div className={classNames(classes.layout, classes.cardGrid)}>
                        <Grid container spacing={8}>

                        <Grid item md={6} lg={4}>
                            <HomeInfoCard />
                        </Grid>
                        <Grid item md={12} lg={8}>
                            <HomeMovieCard />
                        </Grid>

                        </Grid>
                        </div>
                    </CardContent>
                    
                </div>
            </div>
            </Card>
            </Grid>
            </Grid>
        </div>
    )
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };
  
export default withStyles(styles, { withTheme: true })(Home); 