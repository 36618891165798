// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const auth100 = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "auth-100-20190320122941-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://auth-100-20190320122941-hostingbucket-dev.s3-website-us-east-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-1:496165201814:userpool/us-east-1_RL1TZ5Q8r",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RL1TZ5Q8r",
    "aws_user_pools_web_client_id": "77prs8s1poe7qpk1hjr9vfmbe5"
};

export default auth100;
