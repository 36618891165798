// src/hooks/useStyles.js

import { useContext } from 'react';
import Context from '../state/Context'
import { makeStyles } from '@material-ui/styles'
import { fade } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(
    theme => ({
        root: {
            display: 'flex',
            // flexWrap: 'wrap',
            margin: '1px',
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
        },
        button: {
            boxSizing: 'border-box',
            display: 'flex',
            fontFamily: 'Roboto',
            margin: '100px auto',
            width: '100px auto'
        },
        fab: {
            margin: 'theme.spacing.unit',
            extendedIcon: {
                marginRight: 'theme.spacing.unit',
            },
        },
        components__section: {
            display: 'flex',
        },
        components__column: {
            display: 'flex',
            height: '664px',
            flexDirection: 'column',
            margin: '0 8px',
            width: '400px',
        },
          
        }),
    { withTheme: true }
)

export const useTestStyles = makeStyles( theme => ({
      root: {
        background: 'red',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '1px',
        // alignItems: 'center',
        // justifyContent: 'center',
        // width: '210px',
        // height: '110vh'
      },
      paper: {
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        background: '#EEEEEE',
      },
      form: {
        marginTop: 5,
      },
      input: {
        marginTop: 5,
        marginBottom: 5,
      },
      formControl: {
        margin: '1px',
        width: '210px',
        marginTop: 5,
        marginBottom: 5,
      }
    }),
  { withTheme: true }
  );

export const useSignInStyles = makeStyles( theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '1px',
  },
  paper: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    background: '#EEEEEE',
  },
  card: {
    width: 400,
    height: 'auto',
    background: '#EEEEEE',
  },
  cardHeader: {
    background: '#0026ca',
    color: 'white',
  },
  actions: {
    display: 'flex',
    background: '#9effff',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    background: '#0026ca',
    color: 'white',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#9effff',
    color: '#0026ca'
  },
  form: {
    marginTop: 5,
  },
  input: {
    marginTop: 5,
    marginBottom: 5,
    width: 'auto',
  },
  formControl: {
    margin: '1px',
    width: '400px',
    marginTop: 5,
    marginBottom: 5,
  },
  media: {
    height: 0,
    marginTop: '0%', // 16:9
  },
}),
{ withTheme: true }
)


const drawerWidth = () => {
    const { state } = useContext( Context )
    console.log('>>>-useNavStyles-drawerWidth-state->', state)
    console.log('>>>-useNavStyles-drawerWidth-state.drawerWidthIs->', state.drawerWidthIs)
    return state.drawerWidthIs
}

export const useNavStyles = theme => ({
  root: {
    width: '100%',
  },
  appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  appBarShift: {
  width: `calc(100% - ${drawerWidth}px)`,
  marginLeft: drawerWidth,
  transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
  }),
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  hide: {
      display: 'none',
  },
  drawer: {
  width: drawerWidth,
  flexShrink: 0,
  },
  drawerPaper: {
  background: theme.palette.secondary.light,
  width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 1px',
    background: '#9effff',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 1,
    backgroundColor: 'secondary',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

  export const useOptionsStyles = makeStyles( theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '1px',
    },
    // paper: {
    //   padding: 10,
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   background: '#EEEEEE',
    // },
    card: {
      maxWidth: 850,
      height: 'auto',
      background: '#EEEEEE',
    },
    cardHeader: {
      background: '#0026ca',
      color: 'white',
    },
    actions: {
      display: 'flex',
      background: '#9effff',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      background: '#0026ca',
      color: 'white',
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: '#9effff',
      color: '#0026ca'
    },
    form: {
      marginTop: 5,
    },
    input: {
      marginTop: 5,
      marginBottom: 5,
      width: 'auto',
    },
    formControl: {
      margin: '1px',
      width: '200px',
      marginTop: 5,
      marginBottom: 5,
    },
    
    media: {
      height: 0,
      marginTop: '0%', // 16:9
    },
  }),
  { withTheme: true }
  )
